const Footer = () => {
    return (
        <footer>
            <div className="left-footer">
                <p>Digital Products, LLC 2023</p>
            </div>
            <ul className="footer-menu">
                <li><a>Terms</a></li>
                <li><a>Policies</a></li>
                <li><a>Privacy</a></li>
                <li><a>FAQ</a></li>
                <li><a>Help</a></li>
            </ul>
        </footer>
    )
}

export default Footer;