import './styles/App.css';
import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import NotFound from './components/NotFound';
import DashboardContainer from './containers/DashboardContainer';
import Auth from './containers/Auth';
import { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import userStore from './store/userStore';
import Footer from './components/Footer';
import { ModalManager } from "./components/PopUps/ModalManager";
import { ModalProvider } from './context/ModalContext';

const App = observer(() => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkSession();
  }, [])

  const checkSession = async () => {
    await userStore.checkUser();
    setIsLoading(false);
  }

  if (isLoading) {
    return;
  } else return (
    <ModalProvider>
      <ModalManager />
      <div className='flex flex-col w-screen h-screen'>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={userStore.isLogged ? <Navigate to="/dashboard" /> : <Navigate to="/auth" />} />

          <Route path="/dashboard/*" element={userStore.isLogged ? <DashboardContainer /> : <Navigate to="/auth" />} />
          <Route path="/auth/*" element={userStore.isLogged ? <Navigate to="/dashboard" /> : <Auth />} />
        </Routes>
        <Footer />
      </div>
    </ModalProvider>
  )
})

export default App;
